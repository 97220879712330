import { createRouter, createWebHistory } from 'vue-router'
import List from './labels'
import ListPromoter from './labelsPromoter'
import store from '../../store/index'
import Base from '../views/Base.vue'
import account from '../services/account'

let routes: Array<any> = [];


const userInfo = account.getInfo();

if (userInfo) {
  if (userInfo.type === 'organizador' || userInfo.type === 'admin') {
    routes = [
      { name: "Login", path: '/login', component: () => import('../views/Login.vue') },
      {
        path: '/dashboard',
        name: 'Adm',
        component: Base,
        children: List,
        meta: { allowedTypes: ['admin', 'organizador'] }
      },
      { path: "/:catchAll(.*)", redirect: '/dashboard' },
    ];
  } else if (userInfo.type === 'promoter') {
    routes = [
      { name: "Login", path: '/login', component: () => import('../views/Login.vue') },
      {
        path: '/dashboard_promoter',
        name: 'DashboardPromoter',
        component: Base,
        children: ListPromoter,
        meta: { allowedTypes: ['promoter', 'admin'] }
      },
      { path: "/:catchAll(.*)", redirect: '/dashboard_promoter' },
    ];
  }
} else {
  routes = [
    { name: "Login", path: '/login', component: () => import('../views/Login.vue') },
    {
      path: '/dashboard',
      name: 'Adm',
      component: Base,
      children: List,
      meta: { allowedTypes: ['admin', 'organizador'] }
    },
    {
      path: '/dashboard_promoter',
      name: 'DashboardPromoter',
      component: Base,
      children: ListPromoter,
      meta: { allowedTypes: ['promoter', 'admin'] }
    },
    { path: "/:catchAll(.*)", redirect: '/login' }
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL ? process.env.BASE_URL : ''),
  routes
});

router.beforeEach((to, from, next) => {
  store.commit('changeName', to.name);

  const userInfo = account.getInfo();
  const allowedTypes: any = to.meta.allowedTypes || [];

  if (!localStorage.getItem("token_meu_evento") && to.name != "Login") {
    return next({ path: '/login' });
  }
  if (localStorage.getItem("token_meu_evento") && to.name === "Login") {
    return next({ path: '/' });
  }


  if (allowedTypes.length === 0 || allowedTypes.includes(userInfo?.type)) {
    // Usuário tem permissão para acessar a rota
    next();
  } else {
    localStorage.clear()
    next('/login');
  }
});

export default router;

