import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../assets/images/ImagemConviteColaborador.png'


const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "flex justify-end mr-2" }
const _hoisted_4 = {
  key: 0,
  class: "p-7 flex flex-col gap-5 overflow-auto h-full"
}
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_7 = { class: "flex flex-col space-y-2 text-gray-600" }
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = ["id"]
const _hoisted_10 = {
  for: "option1",
  class: "ml-2"
}
const _hoisted_11 = {
  key: 1,
  class: "flex justify-center"
}
const _hoisted_12 = {
  key: 2,
  class: "p-7 flex flex-col gap-5 items-center overflow-auto"
}
const _hoisted_13 = { class: "flex justify-center mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa = _resolveComponent("fa")!

  return ($setup.store.getters.modalConvidarColaborador.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_fa, {
              icon: "xmark",
              size: "lg",
              class: "cursor-pointer lg:mx-2 iconBackMobile absolute text-gray-600",
              onClick: $setup.close
            }, null, 8, ["onClick"])
          ]),
          ($setup.passoMock === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _cache[6] || (_cache[6] = _createElementVNode("div", null, [
                  _createElementVNode("h1", {
                    class: "font-bold",
                    style: {"color":"#757575","font-size":"18px"}
                  }, "Configuração do acesso dos colaboradores")
                ], -1)),
                _createElementVNode("div", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { class: "ml-1 my-1" }, "Email", -1)),
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.colaboradorEmail) = $event)),
                    class: "bg-white border border-gray-500 p-2 pr-10 rounded-lg w-full"
                  }, null, 512), [
                    [_vModelText, $setup.colaboradorEmail]
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"flex flex-col space-y-2 text-gray-600\" data-v-386ea271><h1 class=\"font-semibold\" data-v-386ea271>Tipo de acesso</h1><div class=\"flex items-center\" data-v-386ea271><input type=\"radio\" id=\"produtor\" name=\"role\" class=\"\" data-v-386ea271><label for=\"produtor\" class=\"ml-2\" data-v-386ea271>Produtor</label></div><div class=\"flex items-center\" data-v-386ea271><input type=\"radio\" id=\"sub-produtor\" name=\"role\" class=\"\" data-v-386ea271><label for=\"sub-produtor\" class=\"ml-2\" data-v-386ea271>Sub-Produtor</label></div><div class=\"flex items-center\" data-v-386ea271><input type=\"radio\" id=\"financeiro\" name=\"role\" class=\"\" data-v-386ea271><label for=\"financeiro\" class=\"ml-2\" data-v-386ea271>Financeiro</label></div><div class=\"flex items-center\" data-v-386ea271><input type=\"radio\" id=\"gerenciador\" name=\"role\" class=\"\" data-v-386ea271><label for=\"gerenciador\" class=\"ml-2\" data-v-386ea271>Gerenciador de Promoter</label></div></div>", 1)),
                  _createElementVNode("div", _hoisted_7, [
                    _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "font-semibold" }, "Eventos", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.eventosV, (evento, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "mx-2 my-4"
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("input", {
                            type: "checkbox",
                            id: evento.id,
                            class: ""
                          }, null, 8, _hoisted_9),
                          _createElementVNode("label", _hoisted_10, _toDisplayString(evento.nome), 1)
                        ])
                      ]))
                    }), 128))
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          ($setup.passoMock === false)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "botaoConfirmar",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
                }, "Convidar colaboradores")
              ]))
            : _createCommentVNode("", true),
          ($setup.passoMock === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                  _createElementVNode("h1", {
                    class: "font-bold text-center",
                    style: {"color":"#757575","font-size":"18px"}
                  }, "Convite enviado")
                ], -1)),
                _cache[8] || (_cache[8] = _createElementVNode("img", {
                  src: _imports_0,
                  width: "250",
                  height: "250",
                  class: "mt-5"
                }, null, -1)),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("button", {
                    class: "botaoConfirmar",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
                  }, "Convidar mais colaboradores")
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}