import store from '../../store/index'

export default {
    open(data, callback=() => {}){
        store.commit('modalPagamentoPix',{
            display: true,
            data:data,
            callbackOK: callback
        })
    },
    close(){
        store.commit('modalPagamentoPix', {
            display: false,
            data: {},
            callbackOK: () => {}
        });
    },
}