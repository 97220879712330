const ListPromoter = [
  {
    icon: "home",
    label: "Dashboard",
    name: "PromoterDashboard",
    path: "/dashboard_promoter",
    component: () =>
      import("../views/Views_Promoter/dashboard/promoterDashboard.vue"),
  },
  // {
  //   icon: "paper-plane",
  //   label: "Ingressos",
  //   name: "IngressosPromoter",
  //   path: "/ingressos_promoter",
  //   component: () =>
  //     import("../views/Views_Promoter/ingressos/promoterIngressos.vue"),
  // },
  {
    icon: "dollar",
    label: "Minhas Vendas",
    name: "minhasVendas",
    path: "/minhas_vendas",
    component: () =>
      import("../views/Views_Promoter/minhasVendas/index.vue"),
  },
  {
    show: window.innerWidth < 800,
    icon: 'user',
    name: "Minha Conta Promoter",
    path: "/perfil-promoter",
    component: () => import("../views/Views_Promoter/Perfil/index.vue")
  },
  {
    label: "Emitir Ingresso",
    name: "Voucher Promoter/Novo",
    path: "/voucher_promoter/novo",
    component: () => import("../views/Views_Promoter/ingressos/promoterEmitirIngresso.vue"),
    show: false
  },
  {
    show: false,
    name: "Detalhes da Venda",
    path: "/minhas_vendas/:id/detalhes",
    component: () =>
      import("../views/Views_Promoter/minhasVendas/detalhes.vue"),
  },
  {
    show: true,
    icon: "users",
    label: "Emissão Voucher",
    name: "Emissão Voucher",
    path: "/promoters8d82651b52b5edceaa4218c2dc713255/emitirVoucher",
    component: () => import("../views/Promoter/emitirVoucher.vue"),
  },
  /*{
    show: false,
    icon: "users",
    label: "Promoters",
    name: "Promoters",
    path: "/promoters",
    component: () => import("../views/Promoter/index.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Emissão Voucher",
    name: "Emissão Voucher",
    path: "/promoters/emitirVoucher",
    component: () => import("../views/Promoter/emitirVoucher.vue"),
  },
  {
    show: false,
    icon: "users",
    label: "Liberar ingresso",
    name: "Liberar ingresso",
    path: "/promoters/liberarIngresso",
    component: () => import("../views/Promoter/liberarIngresso.vue"),
  },
  {
    show: false,
    name: "Extrato",
    path: "/promoters/:id/extrato",
    component: () => import("../views/Promoter/extrato.vue"),
  },*/
];

export default ListPromoter;
