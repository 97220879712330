<template>
  <div v-if="store.getters.modalExcluirPromoterPermanente.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col justify-center items-center">

            <div class="flex flex-row justify-center items-center mb-3 bg-yellow-200 p-4" style="color: #FF9800;">
              <fa :icon="`triangle-exclamation`" style="padding: 7px 10px 7px 10px; font-size: 50px;" />
              <p class="mt-2" style="font-size: 20px;">Você realmente deseja excluir esse promoter <b>permanentemente</b>?</p>
            </div>

             <!--<div class="flex flex-row justify-center items-center mb-3 bg-red-300 p-4" style="color: #E22134;">
              <fa :icon="`circle-xmark`" style="padding: 7px 10px 7px 10px; font-size: 50px;" />
              <p class="mt-2" style="font-size: 20px;">Apenas promoters com saldo zerado podem ser excluídos</p>
            </div>!-->

            <div class="title1 text-SC_Azul text-center p-5 mt-3">
      
              <p class="title1 font-bold text-SC_Azul my-3"> <b>{{ promoterV.promoter }}</b></p>
              <p class="title1 font-bold text-SC_Azul my-3"> <b>Saldo: R$ 0,00</b><!--{{ promoterV.value.saldo }}--></p>

              <p>Eventos vinculados:</p>
              <p class="font-bold"> {{ eventoNome }} </p> 
  
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-2">
        <button class="botaoCancelar" @click="close">Cancelar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()
    const eventoNome = ref('')

    eventoNome.value = eventoInfo.getEvento().nome


    const close = () => {
      store.commit("modalExcluirPromoterPermanente", { display: false, promoter: {}, callbackOK: () => { } });
    };


    watch(
      () => [store.getters.modalExcluirPromoterPermanente.display],
      () => {
        if (store.getters.modalExcluirPromoterPermanente.display) {
          promoterV.value = store.getters.modalExcluirPromoterPermanente.promoter
        } else {
          promoterV.value = {}
        }
      })

    const confirmar = async () => {
      store.getters.modalExcluirPromoterPermanente.callbackOK();
    };


    return { store, close, promoterV, eventoID, eventoNome, confirmar };
  },
}
</script>

<style scoped>
.botaoCancelar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoCancelar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid red;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: red;
  color: white;
}

.botaoConfirmar:hover {
  background-color: rgb(156, 41, 41);
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 150px;
  height: 150px;
}
</style>