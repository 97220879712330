<template>
  <div v-if="store.getters.modalPagamentoPix.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto flex items-center flex-col justify-center">
        <!--<h1 class="px-2 text-center mt-4 subtitle2 text-SC_Azul"> Deseja prosseguir com a(s) seguinte(s) emissão(ões)?
        </h1>-->

        <div class="p-4">
          <div class="flex flex-col gap-3 justify-center items-center" style="color: #8E8E8E;">
            <img class="img" src="../../assets/images/qrCodeTeste.png" />

            <p class="font-semibold mt-1" style="font-size: 18px;"> Total: {{ formatarMonetarioBRL(dataV.valorTotal) }}
            </p>
            <p class="text-SC_Azul font-semibold" style="font-size: 16px;"> Válido até: {{
              formatarDataHoraSemFusoHorario(new Date()) }}</p>

          </div>
        </div>

        <div class="px-4 flex flex-col justify-center gap-4">
          <textarea v-model="pixCopiaCola" rows="4" cols="50" class="bg-gray-200" readonly style="resize: none;"
            disabled>
          </textarea>

          <button class="botaoCopiar" @click="copiarPix"> {{!copiar && 'Copiar' || 'Copiado'}}
            <fa :icon="copiar ? 'check' : 'copy'" class="ml-1" />    
          </button>
        </div>



      </div>
      <div class="flex justify-center mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <!--<button class="botaoConfirmar" @click="confirmar">Confirmar</button>-->
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";
import { formatarMonetarioBRL, formatarDataHoraSemFusoHorario } from "@/services/formater";

export default {
  setup() {
    const store = useStore();
    const dataV = ref([])
    const eventoInfo = inject("evento");
    const eventoID = eventoInfo.getEventoID()
    const pixCopiaCola = ref('00020126330014BR.GOV.BCB.PIX0114+5599999999995204000053039865405100.005802BR5914Teste6011São Paulo62070503***63048A1F')
    const alert = inject("alert");
    const copiar = ref(false)

    const close = () => {
      dataV.value = []
      //pixCopiaCola.value = ''
      copiar.value = false
      store.commit("modalPagamentoPix", { display: false, data: {} });
    };

    watch(
      () => [store.getters.modalPagamentoPix.display],
      () => {
        if (store.getters.modalPagamentoPix.display) {

          dataV.value = store.getters.modalPagamentoPix.data
          console.log(dataV.value)

        } else {
          dataV.value = []
        }
      })

    const confirmar = async () => {
      store.getters.modalPagamentoPix.callbackOK();
    };

    const copiarPix = () => {

      try {
        navigator.clipboard.writeText(pixCopiaCola.value)
        copiar.value = true
        alert.open('Sucesso', 'Código Pix copiado para a área de transferência!', 'success')
      } catch (e) {
        alert.open('Erro', 'Erro ao copiar o pix para a área de transferência!', 'error')
      }
    };



    return { store, close, dataV, eventoID, confirmar, formatarMonetarioBRL, formatarDataHoraSemFusoHorario, pixCopiaCola, copiarPix, copiar };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 210px;
  height: 210px;
}

.botaoCopiar {
  background-color: #7D1D54;
  border: 1px solid #7D1D54;
  color: white;
  font-weight: 600;
  border-radius: 3px;
  padding: 8px 10px 8px 10px;
  font-weight: bold;
}
</style>