<template>
  <div v-if="store.getters.modalConfirmarEmissao.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <h1 class="text-center my-4 subtitle2 text-SC_Azul"> Deseja prosseguir com a(s) seguinte(s) emissão(ões)?</h1>
        <div class="flex flex-col px-12 w-full" v-for="(emissao, indexEmissao) in emissaoV" :key="indexEmissao">
          <div class="flex flex-row resumoEmissao mt-5 p-2 gap-3 w-full text-wrap break-all"
            v-for="(comprador, indexComprador) in emissao.comprador" :key="indexComprador">
            <img class="img" :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${eventoID}.jpg`" />

            <div class="flex flex-col w-full">
              <p class="font-semibold" style="font-size: 20px;"> {{ comprador.nome }} </p>
              <p class="font-semibold" style="font-size: 15px;"> {{ comprador.email }} </p>
              <p class="font-semibold" style="font-size: 15px;"> {{ comprador.documento }} </p>
              <p class="font-normal" style="font-size: 17px;"> {{ emissao.produto.label }}</p>
              <p class="font-normal" style="font-size: 17px;"> <span>{{ comprador.quantidade }}</span> uni <span>R$
                  {{ (parseFloat(emissao.produto.valor) * comprador.quantidade).toFixed(2) }} </span> </p>
            </div>
          </div>
        </div>

      </div>
      <div class="flex justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";

export default {
  setup() {
    const store = useStore();
    const emissaoV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()

    const close = () => {
      store.commit("modalConfirmarEmissao", { display: false, emissao: {} });
    };


    watch(
      () => [store.getters.modalConfirmarEmissao.display],
      () => {
        if (store.getters.modalConfirmarEmissao.display) {
          emissaoV.value = store.getters.modalConfirmarEmissao.emissao
        } else {
          emissaoV.value = {}
        }
      })

    const confirmar = async () => {
      store.getters.modalConfirmarEmissao.callbackOK();
    };


    return { store, close, emissaoV, eventoID, confirmar };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  width: 150px;
  height: 150px;
}
</style>