<template>
  <div class="font-sans">
    <modal />

    <modalConfirmation />
    <modalLogout />
    <modalSucessoSuporte/>
    <modalTrocaEventos />
    <modalIngresso />
    <modalVincularPromoter />
    <modalVincularPromoterOutroEvento />
    <modalVisualizarEmissao />
    <modalPromoterMovimentacaoGeral/>
    <modalPromoterMovimentacao/>
    <modalConvidarColaborador/>
    <modalPagamentoPix />
    <modalVendaPix />
    <modalConfirmarEmissao />
    <modalLiberarIngresso />
    <modalExcluirPromoterPermanente />
    <modalDesvincularPromoter />
    <modalRealizarPagamentoPromoter />
    <modalExcluirEmissao />
    <modalCancelarHistoricoEmissao />
    <modalPromoter />
    <modalValorPago />
    <modalPagamento />
    <modalCadastroPromoters />
    <solicitarRetirada />
    <modalSairRetirada />

    <loading />

    <alertbox />
    <router-view />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";

import Loading from "./components/Loading/index";
import AlertBox from "./components/Elements/AlertBox";
import Modal from "./components/Elements/Modal";
import ModalConfirmation from "./components/Elements/ModalConfirmacao.vue";
import modalLogout from "./components/Elements/ModalLogout.vue"
import modalSucessoSuporte from "./components/Elements/ModalSucessoSuporte.vue"
import modalTrocaEventos from "./components/Elements/ModalTrocaEventos.vue";
import ModalIngresso from "./components/Elements/ModalIngresso.vue";
import modalVincularPromoter from "./components/Elements/ModalVincularPromoter.vue"
import modalVincularPromoterOutroEvento from "./components/Elements/ModalVincularPromoterOutroEvento.vue"
import modalVisualizarEmissao from "./components/Elements/ModalVisualizarEmissao.vue"
import modalPromoterMovimentacaoGeral from "./components/Elements/ModalPromoterMovimentacaoGeral.vue"
import modalPromoterMovimentacao from "./components/Elements/ModalPromoterMovimentacao.vue"
import modalConvidarColaborador from "./components/Elements/ModalConvidarColaborador.vue"
import modalPagamentoPix from "./components/Elements/ModalPagamentoPix.vue"
import modalVendaPix from "./components/Elements/ModalVendaPix.vue"
import modalConfirmarEmissao from "./components/Elements/ModalConfirmarEmissao.vue"
import modalLiberarIngresso from "./components/Elements/ModalLiberarIngresso.vue";
import modalExcluirPromoterPermanente from "./components/Elements/ModalExcluirPromoterPermanente.vue";
import modalDesvincularPromoter from "./components/Elements/ModalDesvincularPromoter.vue"
import modalRealizarPagamentoPromoter from "./components/Elements/ModalRealizarPagamentoPromoter.vue";
import modalExcluirEmissao from "./components/Elements/ModalExcluirEmissao.vue"
import modalCancelarHistoricoEmissao from "./components/Elements/ModalCancelarHistoricoEmissao.vue"
import ModalPromoter from "./components/Elements/ModalPromoter.vue";
import ModalValorPago from "./components/Elements/ModalValorPago.vue";
import SolicitarRetirada from "./components/Elements/SolicitarRetirada.vue";
import ModalSairRetirada from "./components/Elements/ModalSairRetirada.vue";
import ModalPagamento from "./components/Elements/ModalPagamento.vue";
import ModalCadastroPromoters from "./components/Elements/ModalCadastroPromoters.vue";

export default {
  name: "App",
  components: {
    alertbox: AlertBox,
    loading: Loading,
    modal: Modal,
    modalConfirmation: ModalConfirmation,
    modalLogout: modalLogout,
    modalSucessoSuporte: modalSucessoSuporte,
    modalTrocaEventos: modalTrocaEventos,
    modalIngresso: ModalIngresso,
    modalVincularPromoter: modalVincularPromoter,
    modalVincularPromoterOutroEvento: modalVincularPromoterOutroEvento,
    modalVisualizarEmissao: modalVisualizarEmissao,
    modalPromoterMovimentacaoGeral: modalPromoterMovimentacaoGeral,
    modalPromoterMovimentacao: modalPromoterMovimentacao,
    modalConvidarColaborador:modalConvidarColaborador,
    modalPagamentoPix: modalPagamentoPix,
    modalVendaPix: modalVendaPix,
    modalConfirmarEmissao: modalConfirmarEmissao,
    modalLiberarIngresso: modalLiberarIngresso,
    modalExcluirPromoterPermanente: modalExcluirPromoterPermanente,
    modalDesvincularPromoter: modalDesvincularPromoter,
    modalRealizarPagamentoPromoter: modalRealizarPagamentoPromoter,
    modalExcluirEmissao: modalExcluirEmissao,
    modalCancelarHistoricoEmissao:modalCancelarHistoricoEmissao,
    modalPromoter: ModalPromoter,
    modalPagamento: ModalPagamento,
    modalCadastroPromoters: ModalCadastroPromoters,
    modalValorPago: ModalValorPago,
    solicitarRetirada: SolicitarRetirada,
    modalSairRetirada: ModalSairRetirada
  },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const isAuth = localStorage.getItem("token_meu_evento") ? true : false;
    
    const getUrlQueryParams = async () => {
      await router.isReady()

      const loginParam = router.currentRoute.value.query.login

      if (loginParam && !isAuth) {
        router.push({ path: "/login", query: { login: loginParam } });
      }
      else
        if (!isAuth) {
          router.push({ path: "/login" });
        }
    };
    getUrlQueryParams()


  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
</style>
