<template>
  <div v-if="store.getters.modalPromoterMovimentacaoGeral.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class=" lg:w-3/4 w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 "
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col">
          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <h1>Movimentações do promoter - {{ promoterV.promoter }}</h1>

            <div class="flex flex-col w-full mt-5">
              <div class="overflow-auto w-full text-left">
                <DatatableAgGrid :rows="dadosPromoterMovimentacaoGeral" :columns="headersPromoterMovimentacaoGeral"
                  :defaultColDef="defaultColDef" :type="'PromoterMovimentacaoGeral'" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="flex flex-row justify-center mt-2 noprint">
        <button class="botaoFechar" @click="close">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject, onMounted } from 'vue';
import moment from "moment-timezone";
import DatatableAgGrid from "@/components/Elements/dataTable/DatatableAgGrid.vue";
import { formatarMonetarioBRL, formatarData, formatarDataIsoData } from "@/services/formater";
import { GET } from "@/services/api";

export default {
  components: {
    DatatableAgGrid
  },
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento")
    const eventoNome = ref('')
    const tipoBotao = ref('')
    const nomePromoter = ref('')
    const loader = inject('loader')

    const total = ref({})

    const close = () => {
      store.commit("modalPromoterMovimentacaoGeral", { display: false, promoter: {}, callbackOK: () => { } });
    };

    const confirmar = async () => {
      store.getters.modalPromoterMovimentacaoGeral.callbackOK();
    };


    watch(
      () => [store.getters.modalPromoterMovimentacaoGeral.display],
      async () => {
        if (store.getters.modalPromoterMovimentacaoGeral.display) {
          promoterV.value = store.getters.modalPromoterMovimentacaoGeral.promoter
          nomePromoter.value = promoterV.value.promoter

          dadosPromoterMovimentacaoGeral.value = []
          total.value = {
            nomeEvento: 'Total',
            //data: formatarDataIsoData(item.data),
            quantidadeVendida: 0,
            valorVendido: 0,
            valorDevido: 0,
            valorPago: 0,
            saldoDevedor: 0,
            nomePromoter: nomePromoter.value
          }

          const response = await GET(`promoter_movements/${promoterV.value.id_promoter}/historico`)

          response.data.map((item) => {

            total.value.quantidadeVendida += parseInt(item.quantidade)
            total.value.valorVendido += parseFloat(item.vendido ?? 0);
            total.value.valorDevido += parseFloat(item.devido)
            total.value.valorPago += parseFloat(item.pago)
            total.value.saldoDevedor += parseFloat(item.pago) + parseFloat(item.devido)
            total.value.nomePromoter = nomePromoter.value

            dadosPromoterMovimentacaoGeral.value.push(
              {
                nomeEvento: item.evento,
                //data: formatarDataIsoData(item.data),
                quantidadeVendida: parseInt(item.quantidade),
                valorVendido: parseFloat(item.vendido),
                valorDevido: parseFloat(item.devido),
                valorPago: parseFloat(item.pago),
                saldoDevedor: parseFloat(item.pago) + parseFloat(item.devido),
                nomePromoter: nomePromoter.value
              }
            )
          })


          dadosPromoterMovimentacaoGeral.value.push(total.value)

        } else {
          promoterV.value = {}
        }
      })


    const headersPromoterMovimentacaoGeral = ref([
      {
        field: 'nomeEvento',
        headerName: 'Evento',
        filter: 'agTextColumnFilter',
        minWidth: 100,
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Evento...',
        },
        cellStyle: params => {
          return { backgroundColor: "#D9D9D9", fontWeight: 600 }
        }
      },
      /*{
        field: 'data',
        headerName: 'Data evento',
        filter: 'agDateColumnFilter',
        minWidth: 100,
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Data do evento...',
        },
        valueFormatter: (params) => {
          return formatarData(params.value)
        },
        cellStyle: params => {
          return { color: 'black', fontWeight: 600 };
        }
      },*/
      {
        field: 'quantidadeVendida',
        headerName: 'Quantidade Vendida',
        minWidth: 100,
        filter: 'agNumberColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por quantidade vendida...',
        },
        cellStyle: params => {
          return { color: 'black', fontWeight: 400 };
        }

      },
      {
        field: 'valorVendido',
        headerName: 'Valor vendido',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por valor vendido...',
        },
        cellStyle: params => {
          return { color: 'black', fontWeight: 600 };
        }
      },
      {
        field: 'valorDevido',
        headerName: 'Valor devido',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por valor devido...',
        },
        cellStyle: params => {
          return { color: 'black', fontWeight: 400 };
        }
      },
      {
        field: 'valorPago',
        headerName: 'Valor pago',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por valor pago...',
        },
        cellStyle: params => {
          return { color: 'black', fontWeight: 400 };
        }
      },
      {
        field: 'saldoDevedor',
        headerName: 'Saldo devedor',
        filter: 'agNumberColumnFilter',
        minWidth: 100,
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por saldo devedor...',
        },
        cellStyle: params => {
          if (params.value >= 0) {
            return { color: 'green', fontWeight: 400 };
          } else if (params.value < 0) {
            return { color: 'red', fontWeight: 400 };
          }
          if (params.data.liberarIngresso)
            return null;
        }
      },

    ]);

    const dadosPromoterMovimentacaoGeral = ref([]);


    const defaultColDef = ref({
      editable: false,
      filter: false,
      resizable: true, // Permite redimensionar
      autoSize: true,  // Ajusta a largura automaticamente
      wrapText: true,
      flex: 1,
    });

    eventoNome.value = eventoInfo.getEvento().nome


    return { store, close, eventoNome, promoterV, confirmar, tipoBotao, headersPromoterMovimentacaoGeral, defaultColDef, dadosPromoterMovimentacaoGeral };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

@media print {
  .noprint {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}
</style>