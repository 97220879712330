import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center p-5 gap-7" }
const _hoisted_5 = { class: "campoDados w-full" }
const _hoisted_6 = { class: "bodytitle" }
const _hoisted_7 = { class: "font-normal" }
const _hoisted_8 = { class: "campoDados w-full" }
const _hoisted_9 = { class: "bodytitle" }
const _hoisted_10 = { class: "font-normal" }
const _hoisted_11 = { class: "campoDados w-full" }
const _hoisted_12 = { class: "bodytitle" }
const _hoisted_13 = { class: "font-normal" }
const _hoisted_14 = { class: "campoDados w-full" }
const _hoisted_15 = { class: "bodytitle" }
const _hoisted_16 = { class: "font-normal" }
const _hoisted_17 = { class: "campoDados w-full" }
const _hoisted_18 = { class: "bodytitle" }
const _hoisted_19 = { class: "font-normal" }
const _hoisted_20 = { class: "campoDados w-full" }
const _hoisted_21 = { class: "bodytitle" }
const _hoisted_22 = { class: "font-normal" }
const _hoisted_23 = { class: "campoDados w-full" }
const _hoisted_24 = { class: "bodytitle" }
const _hoisted_25 = { class: "font-normal" }
const _hoisted_26 = { class: "campoDados w-full" }
const _hoisted_27 = { class: "bodytitle" }
const _hoisted_28 = { class: "font-normal" }
const _hoisted_29 = { class: "campoDados w-full" }
const _hoisted_30 = { class: "bodytitle" }
const _hoisted_31 = { class: "font-normal" }
const _hoisted_32 = { class: "flex justify-center mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalVisualizarEmissao.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, [
                  _cache[1] || (_cache[1] = _createTextVNode("ID Emissão: ")),
                  _createElementVNode("span", _hoisted_7, _toDisplayString($setup.emissaoV.id_emissao), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, [
                  _cache[2] || (_cache[2] = _createTextVNode("Data: ")),
                  _createElementVNode("span", _hoisted_10, _toDisplayString($setup.emissaoV.dataHora), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("p", _hoisted_12, [
                  _cache[3] || (_cache[3] = _createTextVNode("Nome: ")),
                  _createElementVNode("span", _hoisted_13, _toDisplayString($setup.emissaoV.nome), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, [
                  _cache[4] || (_cache[4] = _createTextVNode("E-mail: ")),
                  _createElementVNode("span", _hoisted_16, _toDisplayString($setup.emissaoV.email), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("p", _hoisted_18, [
                  _cache[5] || (_cache[5] = _createTextVNode("Documento: ")),
                  _createElementVNode("span", _hoisted_19, _toDisplayString($setup.emissaoV.documento), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", _hoisted_21, [
                  _cache[6] || (_cache[6] = _createTextVNode("Produto: ")),
                  _createElementVNode("span", _hoisted_22, _toDisplayString($setup.emissaoV.descricao), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("p", _hoisted_24, [
                  _cache[7] || (_cache[7] = _createTextVNode("Comissão: ")),
                  _createElementVNode("span", _hoisted_25, _toDisplayString($setup.emissaoV.comissao), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("p", _hoisted_27, [
                  _cache[8] || (_cache[8] = _createTextVNode("Valor: ")),
                  _createElementVNode("span", _hoisted_28, _toDisplayString($setup.emissaoV.valor), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("p", _hoisted_30, [
                  _cache[9] || (_cache[9] = _createTextVNode("Saldo: ")),
                  _createElementVNode("span", _hoisted_31, _toDisplayString($setup.emissaoV.saldo), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("button", {
              class: "botaoFechar",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Fechar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}